import { FaLinkedin, FaGithub } from 'react-icons/fa';

export const PROJECTS = [
  {
    title: 'Artificial Societies',
    description: 'Building the future of AI agents and simulated environments.',
    tags: ['AI', 'Python', 'React']
  },
  {
    title: 'Behavioral Science Research',
    description: 'Research on human decision-making and cognitive biases.',
    tags: ['Research', 'Analysis', 'Python']
  }
];

export const SOCIAL_LINKS = [
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/in/patrick-sharpe-684250110/',
    Icon: FaLinkedin
  },
  {
    name: 'GitHub',
    url: 'https://github.com/patrick-sharpe',
    Icon: FaGithub
  }
];

export const EXPERIENCES = [
  {
    name: 'Y Combinator',
    role: 'Artificial Societies (W25)',
    period: '2024-2025',
    logoPath: '/images/y-combinator.svg'
  },
  {
    name: 'Swiss Re',
    role: 'Behavioural Science & CX',
    period: '2019-2023',
    logoPath: '/images/swiss-re.svg'
  },
  {
    name: 'Erasmus University',
    role: 'MSc Behavioural Economics',
    period: '2018-2019',
    logoPath: '/images/erasmus-university.svg'
  },
  {
    name: 'Durham University',
    role: 'BA Philosophy, Politics and Economics',
    period: '2015-2018',
    logoPath: '/images/durham-university.svg'
  }
]; 