import React from 'react';
import { EXPERIENCES } from '../../constants/data';
import './Experience.css';

const Experience = () => {
  return (
    <section className="experience-section animate-on-scroll">
      <h2>Other Experiences</h2>
      <div className="experience-grid">
        {EXPERIENCES.map((experience, index) => (
          <div key={index} className="experience-item">
            <div className="experience-logo">
              <img src={experience.logoPath} alt={`${experience.name} logo`} />
            </div>
            <div className="experience-details">
              <h3>{experience.name}</h3>
              <p>{experience.role}</p>
              <span className="experience-period">{experience.period}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Experience; 