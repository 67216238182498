import { useState, useEffect } from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { BsSun, BsMoon } from 'react-icons/bs';
import Experience from './components/Experience/Experience';
import profilePhoto from './profile.jpg';
import './App.css';

function App() {
  const [theme, setTheme] = useState('dark');

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    
    if (savedTheme) {
      setTheme(savedTheme);
    } else if (prefersDark) {
      setTheme('dark');
    }
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme === 'dark' ? 'light' : 'dark');
  };

  return (
    <div className="App">
      <button className="theme-toggle" onClick={toggleTheme} aria-label="Toggle theme">
        {theme === 'dark' ? <BsSun /> : <BsMoon />}
      </button>
      <div className="animated-banner"></div>
      <header className="App-header">
        <div className="content-wrapper">
          <img src={profilePhoto} className="profile-photo" alt="Patrick Sharpe" />
          <h1 className="title">Patrick Sharpe</h1>
          <p className="subtitle">
            Co-founder of{' '}
            <a 
              href="https://societies.io" 
              target="_blank" 
              rel="noopener noreferrer"
              className="company-link"
            >
              Artificial Societies
            </a>
          </p>
          <p className="intro">
            Welcome to my personal website!
          </p>

          <div className="main-content">
            <div className="about-section animate-on-scroll">
              <h2>About Me</h2>
              <p>
              I’ve always been fascinated by how people make decisions. Studying behavioural 
              science taught me the theory, and real-world application showed me the reality. 
              Now, I'm bringing these insights together with AI to build Artificial Societies 
              - a project to understand social influence at scale.
              </p>
            </div>

            <div className="contact-links">
              <a
                href="https://www.linkedin.com/in/patrick-sharpe-684250110/"
                target="_blank"
                rel="noopener noreferrer"
                className="contact-link"
              >
                <FaLinkedin className="icon" />
                <span>LinkedIn</span>
              </a>
              <a
                href="https://github.com/patrick-sharpe"
                target="_blank"
                rel="noopener noreferrer"
                className="contact-link"
              >
                <FaGithub className="icon" />
                <span>GitHub</span>
              </a>
            </div>

            <div className="artificial-societies-section animate-on-scroll">
              <h2>Artificial Societies</h2>
              <div className="artificial-societies-content">
                <a 
                  href="https://societies.io" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="logo-link"
                >
                  <div className="logo-container">
                    <img src="/images/logo-dark.png" alt="Artificial Societies logo" />
                  </div>
                </a>
                <div className="text-content">
                  <p>
                    We simulate collective human behaviour to help organisations 
                    refine their messages, products, and ideas.
                  </p>

                  <p>
                    Social influence is vital for understanding and predicting behaviour change, 
                    and our models provide the first reliable way to capture it.
                  </p>

                  <p>
                    As Chief Product Officer, I prioritise solving high-value business problems by delivering 
                    actionable insights and an intuitive user experience.
                  </p>
                </div>
              </div>
            </div>

            <Experience />
          </div>
        </div>
      </header>
      <div className="animated-banner bottom-banner"></div>
    </div>
  );
}

export default App;
